/* File: /Users/amadkata/linkat/src/App.css */

.App {
  font-family: Arial, sans-serif;
}

/* شريط التنقل */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #3b82f6;
  color: white;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 1rem;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

/* الحاوية الرئيسية */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.dashboard-container {
  display: flex;
  min-height: calc(100vh - 64px); /* تأخذ في الاعتبار ارتفاع شريط التنقل */
}

.sidebar {
  width: 240px;
  background-color: #2D2F33;
  color: #fff;
  padding: 1rem;
}

.main-content {
  flex: 1;
  padding: 2rem;
}

.preview {
  width: 320px;
  background-color: #F5F6FA;
  padding: 1rem;
}

/* رابط linkat */
.linkat-url {
  background-color: #e6f0ff;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy-button {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

/* زر إضافة رابط */
.add-link-button {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  margin: 1rem 0;
}

/* عناصر الرابط */
.link-item {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.link-drag-handle {
  cursor: grab;
  margin-right: 1rem;
}

.link-drag-handle:active {
  cursor: grabbing;
}

.link-content {
  flex: 1;
}

.link-title-input,
.link-url-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.link-actions {
  display: flex;
  gap: 0.5rem;
}

.toggle-button,
.action-button,
.delete-button {
  background-color: #f0f0f0;
  border: none;
  padding: 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}

.toggle-button.active {
  background-color: #4CAF50;
  color: white;
}

/* معاينة الهاتف */
.phone-mockup {
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.preview .avatar {
  width: 60px;
  height: 60px;
  background-color: #3b82f6;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin: 0 auto 1rem;
}

.preview h2 {
  text-align: center;
  margin-bottom: 0.5rem;
}

.preview p {
  text-align: center;
  color: #666;
  margin-bottom: 1rem;
}

.preview-link {
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.empty-state {
  text-align: center;
  color: #666;
  padding: 2rem;
}

.empty-icon {
  font-size: 48px;
  margin-bottom: 1rem;
}

/* تصميم متجاوب */
@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }

  .sidebar,
  .preview {
    width: 100%;
  }
}
