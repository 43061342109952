/* File: /Users/amadkata/linkportal/src/styles/Dashboard.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #8842f2;
  --primary-light: #9f6ff5;
  --primary-dark: #6b35c2;
  --sidebar-bg: #ffffff;
  --sidebar-hover: #f0f0f0;
  --text-light: #ffffff;
  --text-dark: #333333;
  --text-muted: #888888;
  --bg-light: #f7f7f7;
  --bg-white: #ffffff;
  --border-color: #e0e0e0;
  --danger-color: #ff3b30;
  --success-color: #34c759;
  --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  --modal-bg: rgba(0, 0, 0, 0.5);
  --modal-content-bg: #ffffff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--text-dark);
  background-color: var(--bg-light);
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
}

/* ======================
   SIDEBAR
====================== */
.sidebar {
  width: 280px;
  background-color: var(--sidebar-bg);
  color: var(--text-dark);
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  border-right: 1px solid var(--border-color);
  position: relative; /* لعرض القائمة المنسدلة داخله */
}

.top-section {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.avatar-logo-section {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.avatar-logo {
  width: 50px;
  height: 50px;
  background-color: var(--primary-color);
  color: var(--text-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  flex-shrink: 0;
  overflow: hidden;
}

.avatar-logo:hover {
  background-color: var(--primary-dark);
  transform: scale(1.05);
}

.logo {
  font-size: 1.75rem;
  font-weight: 700;
  margin-left: 0.75rem;
  color: var(--primary-color);
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar nav ul li {
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  position: relative;
}

.nav-icon {
  margin-right: 0.75rem;
  font-size: 1.25rem;
}

.sidebar nav ul li:hover,
.sidebar nav ul li.active {
  background-color: var(--sidebar-hover);
  color: var(--primary-color);
}

.sidebar nav ul li a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar nav ul li a svg {
  margin-right: 0.75rem;
}

/* القائمة المنسدلة */
.user-profile-menu {
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%; /* بدلاً من calc(100% - 3rem) */
  background-color: var(--sidebar-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.user-details {
  /* نجعل العنصرين فوق بعض */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar-large {
  width: 60px;
  height: 60px;
  background-color: var(--primary-color);
  color: var(--text-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  overflow: hidden;
  margin-bottom: 0.5rem; /* مسافة تحت الدائرة */
}

.full-username {
  font-size: 1.25rem;
  font-weight: 600;
  /* إظهار الاسم بسطر واحد مع إخفاء بقية الأحرف */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* جعلها بلوك مركزي */
  display: block;
  max-width: 100%;
  text-align: center;
}

.create-new-button {
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-new-button:hover {
  background-color: var(--primary-dark);
}

.menu-section h3 {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: var(--text-muted);
}

.menu-section ul {
  list-style-type: none;
  padding: 0;
}

.menu-section ul li {
  padding: 0.5rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.menu-section ul li:hover {
  color: var(--primary-color);
}

.menu-section ul li svg {
  margin-right: 0.75rem;
}

.menu-actions {
  margin-top: 1rem;
}

.menu-actions li {
  padding: 0.5rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.menu-actions li:hover {
  color: var(--primary-color);
}

.menu-actions li svg {
  margin-right: 0.75rem;
}

/* ======================
   MODAL
====================== */
.modal {
  display: none;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--modal-bg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal.show {
  display: flex;
}

.modal-content {
  background-color: var(--modal-content-bg);
  margin: 0;
  padding: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  width: 50%;
  max-width: 500px;
  box-shadow: var(--box-shadow);
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-muted);
}
.close-button:hover {
  color: var(--text-dark);
}

.modal-content h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--text-dark);
}

.modal-content label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-dark);
}

.modal-content input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}
.modal-content input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.modal-content .modal-buttons {
  display: flex;
  justify-content: flex-end;
}
.modal-content .modal-buttons button {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0.5rem;
  transition: background-color 0.3s ease;
}

.modal-content .modal-buttons .save-button {
  background-color: var(--primary-color);
  color: var(--text-light);
}
.modal-content .modal-buttons .save-button:hover {
  background-color: var(--primary-dark);
}
.modal-content .modal-buttons .cancel-button {
  background-color: var(--border-color);
  color: var(--text-dark);
}
.modal-content .modal-buttons .cancel-button:hover {
  background-color: var(--text-muted);
}
.modal-content .error-message {
  color: var(--danger-color);
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

/* ======================
   MAIN CONTENT
====================== */
.main-content {
  flex-grow: 1;
  padding: 2rem;
  background-color: var(--bg-light);
}

.linkat-url {
  background-color: var(--bg-white);
  padding: 1.5rem;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  box-shadow: var(--box-shadow);
  position: relative;
}

.linkat-url a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
}

.copy-button {
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}
.copy-button:hover {
  background-color: var(--primary-dark);
}

/* رسالة نجاح النسخ */
.copy-success {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--success-color);
  color: var(--text-light);
  padding: 0.5rem 1rem;
  border-radius: 6px;
  opacity: 0;
  animation: fadeInOut 3s forwards;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.buttons {
  display: flex;
  gap: 1rem;
}

.control-button {
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.control-button:hover {
  background-color: var(--primary-dark);
}
.control-button svg {
  margin-right: 0.5rem;
}

.color-picker {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.color-picker label {
  font-weight: 600;
}

.color-picker input[type="color"] {
  border: none;
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 8px;
}

.links-list {
  margin-top: 2rem;
}

.empty-state {
  text-align: center;
  color: var(--text-muted);
  padding: 2rem;
  border: 2px dashed var(--border-color);
  border-radius: 12px;
}

.empty-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.item {
  background-color: var(--bg-white);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s ease;
}
.item:hover {
  box-shadow: var(--box-shadow);
}

.item-drag-handle {
  margin-right: 1rem;
  color: var(--text-muted);
  cursor: grab;
  font-size: 1.25rem;
}
.item-drag-handle:active {
  cursor: grabbing;
}

.item-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.item-input {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  width: 100%;
  transition: border-color 0.3s ease;
}
.item-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.item-actions {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.item-actions button {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}
.item-actions button:hover {
  color: var(--primary-color);
}

/* Toggle Button */
.toggle-button {
  width: 56px;
  height: 28px;
  background-color: var(--border-color);
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.toggle-button.active {
  background-color: var(--success-color);
}
.toggle-button::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: var(--bg-white);
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: 0.3s;
}
.toggle-button.active::after {
  left: 30px;
}

/* زر العين */
.small-eye-icon {
  font-size: 1.25rem;
  color: var(--text-muted);
  cursor: pointer;
  transition: color 0.3s ease;
}
.small-eye-icon:hover {
  color: var(--primary-dark);
}
.preview-eye-icon {
  font-size: 1rem;
  color: var(--primary-color);
}

.delete-button {
  color: var(--danger-color);
  font-size: 1.25rem;
  transition: color 0.3s ease;
}
.delete-button:hover {
  color: #ff0000;
}

/* ======================
   PREVIEW
====================== */
.preview {
  width: 360px;
  padding: 2rem;
  background-color: var(--bg-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid var(--border-color);
}

.phone-mockup {
  background-color: var(--bg-white);
  border-radius: 40px;
  padding: 1rem;
  width: 100%;
  max-width: 320px;
  height: 640px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.phone-screen {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.preview .avatar {
  width: 80px;
  height: 80px;
  font-size: 2rem;
  margin-bottom: 1rem;
  background-color: #000;
  border: 3px solid #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
}

.preview h2 {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.preview p {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--text-muted);
}

.preview-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}

.preview-link {
  background-color: #fff;
  color: var(--text-dark);
  padding: 0.75rem 1rem;
  margin: 0.5rem 0;
  border-radius: 12px;
  width: 100%;
  text-align: left;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
.preview-link:hover {
  background-color: var(--sidebar-hover);
}

.preview-header {
  font-weight: 600;
  margin: 0.5rem 0;
}

.linkat-logo {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  color: var(--text-muted);
  font-weight: 500;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Utility Classes */
.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.text-center {
  text-align: center;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: var(--bg-light);
}
::-webkit-scrollbar-thumb {
  background: var(--primary-light);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* Disable text selection during drag */
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ======================
   RESPONSIVE DESIGN
====================== */
/* شاشات أكبر من 1200px => الوضع العادي */
@media (min-width: 1200px) {
  /* لا تغيير حالياً */
}

/* من 768px إلى 1199px */
@media (max-width: 1199px) {
  .dashboard-container {
    flex-direction: column-reverse; /* المعاينة أولاً, ثم المحتوى */
  }
  .preview {
    order: -1;
    width: 100%;
    border-left: none;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 2rem;
  }
  .sidebar {
    width: 100%;
    border-right: none;
    order: 2; /* أجعلها أخيراً */
  }
}

/* أقل من 768px */
@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }
  .sidebar {
    padding: 1rem;
  }
  .avatar-logo {
    margin-right: 0.5rem;
  }
  .sidebar nav ul {
    width: 100%;
    gap: 0.25rem;
  }
  .sidebar nav ul li {
    width: 100%;
    margin-bottom: 0.5rem;
    justify-content: flex-start;
  }

  .user-profile-menu {
    width: 90%;
    left: 5%;
    top: 60px; /* يمكنك تعديلها حسب ارتفاع .top-section */
  }

  .main-content {
    padding: 1rem;
  }
  .controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .buttons {
    width: 100%;
    gap: 1rem;
  }
  .control-button {
    width: 100%;
    justify-content: center;
  }
  .color-picker {
    width: 100%;
    justify-content: space-between;
  }
  .linkat-url {
    flex-direction: column;
    align-items: flex-start;
  }
  .copy-button {
    margin-top: 1rem;
    width: 100%;
  }
  .item-input {
    padding: 0.75rem;
    font-size: 1rem;
  }

  /* المعاينة */
  .preview {
    width: 100%;
    border-left: none;
    padding: 1rem;
  }
  .phone-mockup {
    max-width: 360px;
    margin: 0 auto;
    height: 600px; /* تقليل الارتفاع قليلاً */
  }
  .preview .avatar {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
  }
  .preview h2 {
    font-size: 1rem;
  }
  .small-eye-icon {
    font-size: 1rem;
  }
  .preview-eye-icon {
    font-size: 0.875rem;
  }
}
