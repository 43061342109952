/* File: /Users/amadkata/linkportal/src/styles/PublicProfile.css */

/* استيراد الخط */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.public-profile {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  /* أزلنا الخلفية الافتراضية بحيث يمكن للخلفية أن تكون شفافة أو ملونة حسب jsx */
  background-size: cover;
  background-position: center;
  padding: 40px 20px;
  transition: background-color 0.3s ease;
}

.profile-container {
  /* يمكنك تعديل هذه القيم أو حذفها إذا لا تريده يكون هناك أي خلفية */
  width: 100%;
  max-width: 600px;

  /* في النسخة الأصلية كان: background-color: rgba(255, 255, 255, 0.1); */
  /* لو أردت إزالة المستطيل تمامًا، اجعلها شفافة أو أزل السطر: */
  background-color: transparent;
  
  /* أزلنا backdrop-filter: blur(10px); حتى لا يكون هناك ضبابية */
  border-radius: 20px;
  padding: 40px;
  /* بإمكانك أيضًا إزالة الظل إذا لا تريده */
  box-shadow: none; /* كان 0 8px 32px rgba(0, 0, 0, 0.1); */
}

.profile-header {
  text-align: center;
  margin-bottom: 30px;
}

/* الشكل الدائري للصورة أو الأفاتار */
.avatar,
.profile-picture {
  width: 120px;
  height: 120px;
  font-size: 48px;
  margin: 0 auto 20px;
  background-color: #ffffff;
  color: #4C6FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* ظل خفيف */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.profile-picture {
  object-fit: cover;
}

.username {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ffffff;
}

.bio {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

.social-links a {
  color: #ffffff;
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.1);
  color: rgba(255, 255, 255, 0.7);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* أزلنا أقسام اللودر (.loader-container, .loader, .loading-text, إلخ) */

/* نص العنوان */
.header-text {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 25px 0 15px;
  color: #ffffff;
  text-align: center;
}

/* زر الرابط */
.link-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  padding: 15px;
  margin: 10px 0;
  text-decoration: none;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.link-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.link-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.linkat-logo {
  margin-top: 40px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

/* استجابة للشاشات الصغيرة */
@media (max-width: 480px) {
  .profile-container {
    padding: 30px 20px;
  }

  .username {
    font-size: 1.8rem;
  }

  .bio {
    font-size: 1rem;
  }

  .link-item {
    font-size: 0.9rem;
  }
}
